// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("@popperjs/core")
require("bootstrap/dist/js/bootstrap")
require("select2")
require("channels")
require("trix")
require("@rails/actiontext")

require('jquery')
window.$ = $

  // import Swiper JS
import Swiper, { Autoplay, Lazy, Pagination } from 'swiper';
window.Swiper = Swiper;

// import Swiper styles
import('controllers')

// nouislider
import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.css';
window.noUiSlider = noUiSlider;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
import('trix_customisations');

import '../stylesheets/application';

// Select 2
import 'select2'
import 'select2/dist/css/select2.css'

// Polyfills
import './custom_event_polyfill.js'
import './foreach_polyfill.js'
import 'url-polyfill'

// $(document).on("turbolinks:before-cache", function() {
//   $('select.select2').select2('destroy');
// });
$(document).on("ready", function() {
  $('select.select2').select2('destroy');
});

// $(document).on('turbolinks:load', function() {
//   $('select.select2').select2({ width: '100%' });
//   $('[data-toggle="tooltip"]').tooltip();
// });
$(document).on('ready', function() {
  $('select.select2').select2({ width: '100%' });
});

$(document).ready(function() {
  // Gets the video src from the data-src on each button
  var $videoSrc;
  $('.video-btn').click(function() {
      $videoSrc = $(this).data( "src" );
  });

  // when the modal is opened autoplay it
  $('#teacherVideoModal').on('shown.bs.modal', function (e) {
    // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
    $("#teacherVideoModalFrame").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0" );
  })

  // stop playing the youtube video when I close the modal
  $('#teacherVideoModal').on('hide.bs.modal', function (e) {
      // a poor man's stop video
      $("#teacherVideoModalFrame").attr('src', $videoSrc);
      // var videoPlayer = new Vimeo($("#teacherVideoModalFrame"));
      // videoPlayer.stop();
  })
// document ready
  initBootstrapMenus();
});


$(() => {
  var swiper = new Swiper('.swiper:not(.swiper-instruments):not(.swiper-mobile)', {
    lazy: true,
    loadPrevNextAmount: 2,
    spaceBetween: 32,
    slidesPerView: 'auto',
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    modules: [Autoplay, Lazy, Pagination]
  });

  var mobileSwiper = new Swiper('.swiper-mobile', {
    slidesPerView: "auto",
    spaceBetween: 32,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      enabled: true
    },
    breakpoints: {
      992: {
        enabled: false,
        spaceBetween: 0,
        pagination: {
          enabled: false
        }
      },
    },
    modules: [Pagination]
  });

  var instrumentSwiper = new Swiper('.swiper-instruments', {
    slidesPerView: "auto",
    spaceBetween: 8,
    breakpoints: {
      992: {
        enabled: false,
        spaceBetween: 0
      },
    },
  });

});

var isTouchDevice = function () {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};
var isSmallScreen = function () {
  return window.innerWidth < 992;
};

document.isTouch = isTouchDevice();

var initBootstrapMenus = function () {

  // reset all menu handlers first, using jQuery off
  $(
    document, "nav.navbar li.dropdown > a.dropdown-toggle, nav.navbar li.dropdown-submenu > a.dropdown-toggle, nav.navbar li a:not(.dropdown-toggle)"
  ).off("click");
  $("nav.navbar li.dropdown, nav.navbar li.dropdown-submenu").off(
    "mouseenter mouseleave"
  );

  $(document).on("click", function (e) {
    if (
      !$(e.target).parents("nav.navbar:first").length > 0
    ) {
      $('nav.navbar li.dropdown .show').removeClass('show');
    }
  });

  const template = document.querySelector('#submenu-back-contents-template');

  $(".dropdown-menu:not(:has(> .nav-item .nav-link-back))").each(function () {
    const clone = template.content.querySelector('li:first-child').cloneNode(true);
    let $menu = $(this);
    $menu.prepend(clone);
    $menu.find('> .nav-item .nav-link-back').on('click', function () {
      $menu.removeClass('show');
    });
  })


  let menuTimeouts = [];
  // initialize all menus with unique id for quick state checking
  $(
    "nav.navbar li.dropdown:not([data-menu-id]), nav.navbar li.dropdown-submenu:not([data-menu-id])"
  ).each(function (e) {
    $(this).attr(
      "data-menu-id",
      "menu" + Math.round(Math.random() * 1000000000)
    );
  });

  // check if clicks or hovers must be used, depending on touch device or visible .navbar-toggle button




  // on window resize, remove all show classes from the submenus
  $(window).resize(function () {
    $('nav.navbar li.dropdown .show').removeClass('show');
    updateToggleSubHandlers();
  })

  $('.menu-toggle').click(function () {
    // toggle body class
    if ($('body').hasClass('show-menu')) {
      $('nav.navbar li.dropdown .show').removeClass('show');
    }
    $('body').toggleClass('show-menu');
  });

  $("nav.navbar li.dropdown").hover(

    function (e) {
      // show submenu
      clearTimeout(menuTimeouts[$(this).attr("data-menu-id")]);
      if (!isSmallScreen() && !isTouchDevice()) {

        var $subMenu = $(this).find(".dropdown-menu:first");
        $subMenu.addClass("show");

        if (!$subMenu.is(':entireonscreen')) {
          $subMenu.addClass("dropdown-menu-left")
        } else if (!$subMenu.hasClass("dropdown-menu-left")) {
          $subMenu.removeClass("dropdown-menu-left")
        }
      }
    },
    function (e) {
      if (!isSmallScreen() && !isTouchDevice()) {
        // hide submenu timed
        var $subMenu = $(this).find("> .dropdown-menu");
        menuTimeouts[$(this).attr("data-menu-id")] = setTimeout(() => {
          $subMenu.removeClass("show");
        }, 150);
      }
    }
  );

  // do not use hover, use clicks (smalls screens and touch devices)
  function setupToggleSubClickEvent() {
    $(
      isSmallScreen()
        ? "nav.navbar li.dropdown > .toggle-sub, nav.navbar li.dropdown-submenu > .toggle-sub"
        : "nav.navbar li.dropdown > a.dropdown-toggle, nav.navbar li.dropdown-submenu > a.dropdown-toggle"
    ).on("click", function (e) {
      if (isSmallScreen() || isTouchDevice()) {
        if (isTouchDevice() && $(this).parents("li:first").find("> .dropdown-menu.show:first").length > 0) {
          return true;
        }
        e.preventDefault();

        var that = this;
        // hide all other submenus first (but not this one)
        $(this)
          .parents("ul:first")
          .find("> li.dropdown, > li.dropdown-submenu")
          .each(function (i) {
            if (
              $(this).attr("data-menu-id") !==
              $(that).parent().attr("data-menu-id")
            ) {
              $(this).find(".dropdown-menu").removeClass("show");
            }
          });

        var $subMenu = $(this).parents('li:first').find("> .dropdown-menu");
        if ($subMenu.length > 0) {
          if ($subMenu.hasClass("show")) {
            // if submenu of this menu item is showing, hide all submenus and go with the flow (e.g. open link)
            $(this)
              .parents("ul.navbar-nav:first")
              .find(".dropdown-menu")
              .removeClass("show");
            return true;
          } else {
            // if submenu of this menu item is hidden, show it and disable link functionality
            $subMenu.addClass("show");
            return false;
          }
        }
      }
      return true;
    });
  }

  // Call the function during initialization
  setupToggleSubClickEvent();

  // Call the function in the updateToggleSubHandlers function
  function updateToggleSubHandlers() {
    // Remove existing click event handlers for .toggle-sub
    $("nav.navbar li.dropdown > .toggle-sub, nav.navbar li.dropdown-submenu > .toggle-sub").off("click");

    // Reattach click event handlers based on the current screen size
    if (isSmallScreen() || isTouchDevice()) {
      setupToggleSubClickEvent();
    }
  }

  // always hide submenus when clicking a link without submenu
  $("nav.navbar li a:not(.dropdown-toggle").on("click", function (e) {
    if (!isSmallScreen() && !isTouchDevice()) {
      $(this).parents("ul.navbar-nav").find(".dropdown-menu").removeClass("show");
    }
  });

}

$.fn.isOnScreen = function (partial) {

  //let's be sure we're checking only one element (in case function is called on set)
  var t = $(this).first();

  //we're using getBoundingClientRect to get position of element relative to viewport
  //so we dont need to care about scroll position
  var box = t[0].getBoundingClientRect();

  //let's save window size
  var win = {
    h: $(window).height(),
    w: $(window).width()
  };

  //now we check against edges of element

  //firstly we check one axis
  //for example we check if left edge of element is between left and right edge of scree (still might be above/below)
  var topEdgeInRange = box.top >= 0 && box.top <= win.h;
  var bottomEdgeInRange = box.bottom >= 0 && box.bottom <= win.h;

  var leftEdgeInRange = box.left >= 0 && box.left <= win.w;
  var rightEdgeInRange = box.right >= 0 && box.right <= win.w;


  //here we check if element is bigger then window and 'covers' the screen in given axis
  var coverScreenHorizontally = box.left <= 0 && box.right >= win.w;
  var coverScreenVertically = box.top <= 0 && box.bottom >= win.h;

  //now we check 2nd axis
  var topEdgeInScreen = topEdgeInRange && (leftEdgeInRange || rightEdgeInRange || coverScreenHorizontally);
  var bottomEdgeInScreen = bottomEdgeInRange && (leftEdgeInRange || rightEdgeInRange || coverScreenHorizontally);

  var leftEdgeInScreen = leftEdgeInRange && (topEdgeInRange || bottomEdgeInRange || coverScreenVertically);
  var rightEdgeInScreen = rightEdgeInRange && (topEdgeInRange || bottomEdgeInRange || coverScreenVertically);

  //now knowing presence of each edge on screen, we check if element is partially or entirely present on screen
  var isPartiallyOnScreen = topEdgeInScreen || bottomEdgeInScreen || leftEdgeInScreen || rightEdgeInScreen;
  var isEntirelyOnScreen = topEdgeInScreen && bottomEdgeInScreen && leftEdgeInScreen && rightEdgeInScreen;

  return partial ? isPartiallyOnScreen : isEntirelyOnScreen;

};

$.expr.filters.onscreen = function (elem) {
  return $(elem).isOnScreen(true);
};

$.expr.filters.entireonscreen = function (elem) {
  return $(elem).isOnScreen(false);
};
